<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('promotion.new.title')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">
            <vue-topprogress ref="topProgress"></vue-topprogress>
            <!-- <div class="box-actions">
                <a  v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                   ><i class="fa fa-pencil"></i></a>
                   <el-select class="select-default" size="large"  :name="$t('contract.form.link_type')"
                                                           v-model="room.status">
                                                    <el-option v-for="option in info_page.states" class="select-default" :value="option.value"
                                                               :label="option.name" :key="option.value">
                                                    </el-option>
                                                </el-select>
            </div>-->

            <div class="col-12">
                <card>
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory promotions">
                                <div class="form-updates w-border"  >
                                    <l-switch v-model="promotion_info.visible.min_nights" type="primary" on-text="On" off-text="Off"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.minimun_nights.title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion_info.visible.min_nights">

                                            <div class="group-item">
                                                <label class="fw">{{$t('promotion.form.minimun_nights.min')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.min_nights.min_value">
                                                </fg-input>
                                                <span class="btn-link ml-3" @click="promotion.min_nights.max_status = true">
                                                        {{$t('promotion.form.minimun_nights.add')}}
                                                    </span>
                                            </div>
                                            <div class="group-item" v-if="promotion.min_nights.max_status">
                                                <label class="fw">{{$t('promotion.form.minimun_nights.max')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.min_nights.max_value">
                                                </fg-input>
                                                <span class="btn-link ml-3" @click="deleteElement('min_nights')">
                                                    {{$t('promotion.form.minimun_nights.delete')}}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <!--<div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4">
                                                <button type="button" class="add-item" @click="info_page.index_page=3">Vista previa</button>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                                <div class="form-updates w-border">
                                    <l-switch v-model="promotion_info.visible.advance_options" type="primary" on-text="On" off-text="Off"></l-switch>
                                    <div class="row" >
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.days_advaces.title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion_info.visible.advance_options">

                                            <div class="form-check"  >
                                                <fg-input>
                                                    <radio label="0" v-model="promotion.advance_options.option">{{$t('promotion.form.days_advaces.advance')}}</radio>
                                                    <radio label="1" v-model="promotion.advance_options.option" >{{$t('promotion.form.days_advaces.today')}}</radio>
                                                </fg-input>
                                            </div>


                                            <div class="group-item" v-show="promotion.advance_options.option === '0'">
                                                <label class="fw">{{$t('promotion.form.days_advaces.min')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.advance_options.min_days"
                                                >
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.days_text')}}</span>
                                                <span class="btn-link  ml-3" @click="promotion.advance_options.max_status = true">
                                                        {{$t('promotion.form.days_advaces.add')}}
                                                    </span>
                                            </div>
                                            <div class="group-item" v-show="promotion.advance_options.max_status && promotion.advance_options.option === '0'">
                                                <label class="fw">{{$t('promotion.form.days_advaces.max')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.advance_options.max_days"
                                                >
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.days_text')}}</span>
                                                <span class="btn-link  ml-3"  @click="deleteElement('advance_options')">
                                                    {{$t('promotion.form.days_advaces.delete')}}
                                                </span>
                                            </div>


                                            <div class="group-item mt-3" v-show="promotion.advance_options.option === '1'">
                                                <label class="fw" >{{$t('promotion.form.days_advaces.can_reserve')}}</label>
                                                <!--<el-select class="select-default" size="large" >
                                                   &lt;!&ndash; <el-option v-for="option in info_page.states" class="select-default" :value="option.value"
                                                               :label="option.name" :key="option.value">
                                                    </el-option>&ndash;&gt;
                                                </el-select>-->
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.text_hour')}}</span>
                                                <label class="full-width">{{$t('promotion.form.days_advaces.info_show')}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row"  v-show="promotion_info.visible.advance_options">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item" @click="info_page.index_page=2">Guardar</button>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item second">Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-updates w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.promotion.title')}}</label>
                                            </div>
                                            <div class="group-edit" v-show="info_page.index_page>1">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=1"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=1"> Editar</a>
                                            </div>
                                        </div>



                                        <div class="col-md-12" v-show="info_page.index_page===1">

                                            <div class="form-check"  >
                                                <label class="fw">
                                                    {{$t('promotion.form.promotion.type_question')}}
                                                </label>
                                                <fg-input>
                                                    <radio label="0"  v-model="promotion.type.option"><span class="fw">{{$t('promotion.form.promotion.unique')}}</span> <span class="fllw">{{$t('promotion.form.promotion.unique_description')}}</span></radio>

                                                    <radio label="1" v-model="promotion.type.option" ><span class="fw">{{$t('promotion.form.promotion.each_day')}}</span> <span class="fllw">{{$t('promotion.form.promotion.each_description')}}</span></radio>

                                                    <radio label="2"  v-model="promotion.type.option"><span class="fw">{{$t('promotion.form.promotion.always_day')}}</span> <span class="fllw">{{$t('promotion.form.promotion.always_description')}}</span></radio>

                                                </fg-input>
                                            </div>


                                            <div class="group-item" v-show="promotion.type.option === '0'">
                                                <label class="fw" style="width: 150px">{{$t('promotion.form.promotion.type.title')}}</label>
                                                <label class="fw ml-3"  style="width: 150px">{{$t('promotion.form.promotion.type.discount')}}</label><br>
                                                <fg-input v-model="promotion.type.type" class="input-default">
                                                    <el-select class="select-default" size="large" v-model="promotion.type.type">
                                                        <el-option v-for="option in promotion_info.options.type" class="select-default" :value="option.value"
                                                                   :label="option.name" :key="option.value">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                                <fg-input type="text"
                                                          class="input-default ml-3"
                                                          placeholder=""
                                                          v-model="promotion.type.value">
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.promotion.type.description')}}</span>
                                                <span class="fllw">{{$t('promotion.form.promotion.type.legend')}}</span>


                                            </div>


                                            <div class="group-item mt-3" v-show="promotion.type.option === '1'">
                                                <div class="week-form lw-head" >
                                                    <div class="item-7" data-key="0">
                                                        <span>Lun</span>
                                                        <fg-input type="text" v-model="promotion.type.days[1]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Mar</span>
                                                        <fg-input type="text" v-model="promotion.type.days[2]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Mie</span>
                                                        <fg-input type="text" v-model="promotion.type.days[3]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Jue</span>
                                                        <fg-input type="text" v-model="promotion.type.days[4]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Vie</span>
                                                        <fg-input type="text" v-model="promotion.type.days[5]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Sab</span>
                                                        <fg-input type="text" v-model="promotion.type.days[6]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="0">
                                                        <span>Dom</span>
                                                        <fg-input type="text" v-model="promotion.type.days[0]"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                </div>
                                                <span class="legend-tip">{{$t('promotion.form.promotion.type.legend')}}</span>
                                            </div>


                                            <div class="group-item" v-show="promotion.type.option === '2'">
                                                <label class="fw fllw">{{$t('promotion.form.promotion.quesion_1')}}</label>
                                                <fg-input v-model="promotion.type.offer_type" class="input-default">
                                                    <el-select class="select-default" size="large" v-model="promotion.type.offer_type">
                                                        <el-option v-for="option in promotion_info.options.discount" class="select-default" :value="option.value"
                                                                   :label="option.name" :key="option.value">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>

                                                <label class="fw fllw">{{$t('promotion.form.promotion.quesion_2')}}</label>
                                                <fg-input v-model="promotion.type.discount_day" class="input-default">
                                                    <el-select class="select-default" size="large" v-model="promotion.type.discount_day">
                                                        <el-option v-for="option in promotion_info.options.discount_apply" class="select-default" :value="option.value"
                                                                   :label="option.name" :key="option.value">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>

                                                <label class="fw fllw">{{$t('promotion.form.promotion.percent_discount')}}</label>
                                                <fg-input v-model="promotion.type.discount_value" class="input-default">
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.promotion.type.tip')}}</span>


                                                <div class="form-check">
                                                    <label for="limit_one" class="form-check-label check-admin">
                                                        <input class="form-check-input" type="checkbox" id="limit_one" v-model="promotion.type.is_limit">
                                                        <span class="form-check-sign" ></span>
                                                        <span>{{$t('promotion.form.promotion.limit_one')}}</span>
                                                    </label>
                                                </div>

                                            </div>



                                            <div class="group-item" >
                                                <span class="btn-link mt-4" @click="promotion.type.limit_status = true">
                                                    {{$t('promotion.form.promotion.add')}}
                                                </span>
                                                <div v-show="promotion.type.limit_status">
                                                    <label class="fw">{{$t('promotion.form.promotion.limit')}}</label>
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              v-model="promotion.type.limit"

                                                    >
                                                    </fg-input>
                                                    <span class="ml-3">{{$t('promotion.form.promotion.room_days')}}</span>
                                                    <span class="fa fa-minus ml-3" @click="deleteElement('type')">
                                                </span>
                                                </div>
                                            </div>

                                            <div class="group-item mt-3"  >
                                                <label class="fw fllw" >{{$t('promotion.form.promotion.apply')}}</label>
                                                <span>{{$t('promotion.form.promotion.apply_legend')}}</span>
                                                <span class="btn-link ml-3 fa fa-pencil-square-o"></span>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="row"  v-show="info_page.index_page===1">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item" @click="info_page.index_page=2">Siguiente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-updates form-dates  w-border" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.booking.title')}}</label>
                                            </div>
                                            <div class="group-edit" v-show="info_page.index_page>2">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=2"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=2"> Editar</a>
                                            </div>

                                        </div>
                                        <div class="col-md-12" v-show="info_page.index_page===2">

                                            <div class="group-item">
                                                <label class="fw">{{$t('promotion.form.booking.clients.title')}}</label>
                                                <fg-input v-model="promotion.booking.option" class="input-default" style="width: 250px">
                                                    <el-select class="select-default" size="large" v-model="promotion.booking.option" style="width: 250px">
                                                        <el-option v-for="option in promotion_info.options.reserve" class="select-default" :value="option.value"
                                                                   :label="option.name" :key="option.value">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                            </div>


                                            <div class="group-item"  v-show="promotion.booking.option === '0'">
                                                <label class="fw mr-3" style="width: 300px">{{$t('promotion.form.booking.from')}}</label>
                                                <label class="fw ml-3"  style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>
                                                <fg-input class="input-default">
                                                    <el-date-picker v-model="info_page.start_date" type="date" placeholder="Elige una fecha"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1" @change="pickDate()">
                                                    </el-date-picker>
                                                </fg-input>
                                                <fg-input class="input-default ml-3">
                                                    <el-time-select
                                                            v-model="promotion.booking.from_hour"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59'
                                                                    }"
                                                            placeholder="">
                                                    </el-time-select>
                                                </fg-input>
                                                <fg-input class="input-default ml-3">
                                                    <el-date-picker v-model="info_page.start_date" type="date" placeholder="Elige una fecha"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1" @change="pickDate()">
                                                    </el-date-picker>
                                                </fg-input>
                                                <fg-input class="input-default ml-3">
                                                    <el-time-select
                                                            v-model="promotion.booking.to_hour"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59'
                                                                    }"
                                                            placeholder="">
                                                    </el-time-select>
                                                </fg-input>

                                            </div>


                                            <div class="group-item box-dates mt-4"   v-show="promotion.booking.option === '1'">
                                                <div class="row">
                                                    <div class="col-md-12">


                                                        <label class="fw mr-3" style="width: 250px">{{$t('promotion.form.booking.from')}}</label>
                                                        <label class="fw ml-3"  style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>


                                                        <fg-input style="width: 500px">
                                                            <el-date-picker v-model="promotion.booking.date_range" type="daterange" placeholder="Inicio"
                                                                            format="dd-MM-yyyy"
                                                                            :picker-options="pickerOptions1"
                                                                            range-separator="a"
                                                                            start-placeholder="Fecha inicio"
                                                                            end-placeholder="Fecha fin"
                                                            >
                                                            </el-date-picker>
                                                        </fg-input>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="box-days">

                                                            <label class="fw mr-3">{{$t('promotion.form.booking.only_days')}}</label>



                                                            <div class="form-check">
                                                                <label for="lunes_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="lunes_d" v-model="promotion.booking.week[1]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Lun.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="martes_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="martes_d" v-model="promotion.booking.week[2]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Mar.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="miercoles_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="miercoles_d" v-model="promotion.booking.week[3]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Mie.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="jueves_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="jueves_d" v-model="promotion.booking.week[4]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Jue.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="viernes_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="viernes_d" v-model="promotion.booking.week[5]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Vie.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="sabado_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="sabado_d" v-model="promotion.booking.week[6]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Sab.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="domingo_d" class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox" id="domingo_d" v-model="promotion.booking.week[0]">
                                                                    <span class="form-check-sign" ></span>
                                                                    Dom.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="group-item mt-3">
                                                <label class="fw fllw" >{{$t('promotion.form.booking.between_time')}}</label>

                                                <fg-input class="input-default">
                                                    <el-time-select
                                                            v-model="promotion.booking.from_hour"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59'
                                                                    }"
                                                            placeholder="">
                                                    </el-time-select>
                                                </fg-input>


                                                <span class="ml-3">{{$t('promotion.form.booking.a')}}</span>
                                                <fg-input class="input-default ml-3">
                                                    <el-time-select
                                                            v-model="promotion.booking.to_hour"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59'
                                                                    }"
                                                            placeholder="">
                                                    </el-time-select>
                                                </fg-input>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="row" v-show="info_page.index_page===2">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item" @click="info_page.index_page=3">Siguiente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-dates w-border" >
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="group-edit" v-show="info_page.index_page>3">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=3"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=3"> Editar</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-dates  mt-3">
                                        <div class="row ">
                                            <div class="col-md-12">
                                                <label class="subtitle">{{$t('promotion.form.travel.title')}}</label>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="box-dates"  v-show="info_page.index_page===3">
                                        <div class="row ">
                                            <div class="col-md-12">
                                                <label class="fw mr-3" style="width: 250px">{{$t('promotion.form.booking.from')}}</label>
                                                <label class="fw ml-3"  style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>
                                                <fg-input style="width: 500px" >
                                                    <el-date-picker v-model="promotion.travel.date_range" type="daterange" placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-holder="Fecha inicio"
                                                                    end-placeholder="Fecha fin"
                                                                    @input="printCalendar()"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="dates-manually"  v-show="info_page.index_page===3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="mb-5">{{$t('promotion.form.travel.add')}}</label>
                                            </div>


                                            <div class="box-calendar" >


                                                <div class="item-month" v-for="(month_s, idsx) in calendar_info.monthsHead">
                                                    <div class="line-week lw-head" >
                                                        <span class="fa fa-chevron-left sp-icon" v-if="idsx===0"  @click="updDate('minus')"></span>
                                                        <label class="calendar-title wout-icon" :class="checkClass(idsx)" @click="addMonth(month_s)">{{nameMonth(month_s)}}</label>
                                                        <span class="fa fa-chevron-right sp-icon" v-if="idsx===2"  @click="updDate('plus')"></span>
                                                    </div>


                                                    <div class="line-week lw-head" >
                                                        <div class="item-7" data-key="0">D</div>
                                                        <div class="item-7" data-key="1">L</div>
                                                        <div class="item-7" data-key="2">M</div>
                                                        <div class="item-7" data-key="3">M</div>
                                                        <div class="item-7" data-key="4">J</div>
                                                        <div class="item-7" data-key="5">V</div>
                                                        <div class="item-7" data-key="6">S</div>
                                                    </div>

                                                    <div class="line-week"  v-for="day_s in checkMonth(month_s)">
                                                        <div class="item-7" @click.capture="addCalendar(day_s[0])">  <span  :class="checkDay(day_s[0])?'select-day':''">{{dayCalendar(day_s[0])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[1])">  <span  :class="checkDay(day_s[1])?'select-day':''">{{dayCalendar(day_s[1])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[2])">  <span  :class="checkDay(day_s[2])?'select-day':''">{{dayCalendar(day_s[2])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[3])">  <span  :class="checkDay(day_s[3])?'select-day':''">{{dayCalendar(day_s[3])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[4])">  <span  :class="checkDay(day_s[4])?'select-day':''">{{dayCalendar(day_s[4])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[5])">  <span  :class="checkDay(day_s[5])?'select-day':''">{{dayCalendar(day_s[5])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[6])">  <span  :class="checkDay(day_s[6])?'select-day':''">{{dayCalendar(day_s[6])}}</span></div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-12">
                                                <div class="box-legends">
                                                    <label>
                                                        <span class="example-picker"></span>Travel Window
                                                    </label>
                                                    <label>
                                                        <span class="example-picker blocks"></span>Bloqueos
                                                    </label>


                                                    <label class="right-t color-blue" @click="resetCalendar">
                                                        Restablecer
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="box-button mb-4">
                                                    <button type="button" class="add-item"  @click="orderDate">Siguiente</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-updates form-dates  w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.info.title')}}</label>
                                            </div>
                                            <div class="group-edit"  v-show="info_page.index_page>4">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=4"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=4"> Editar</a>
                                            </div>
                                        </div>
                                        <div class="col-md-12"  v-show="info_page.index_page === 4">

                                            <div class="group-item">
                                                <label class="fw">{{$t('promotion.form.info.name')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          style="width: 50%"
                                                          placeholder=""
                                                          v-model="promotion.info.name"
                                                >
                                                </fg-input>
                                                <span class="fllw">{{$t('promotion.form.info.description')}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-show="info_page.index_page===4">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item" @click="info_page.index_page=5">Siguiente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <!--    <div class="form-preview w-border"  v-show="info_page.index_page===2 || info_page.index_page===3">
                                        <label class="l-title">Seleccionar fechas</label>
                                        <span class="brl text-normal text-capitalize">{{calendar_info.min}} - {{calendar_info.max}}</span>
                                        <span class="brl text-normal">Se actualizarán {{calendar_info.selected.length}} {{calendar_info.selected.length===1?'día':'días'}} </span>
                                        <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                        <a class="a-right type-text"  @click="info_page.index_page=1"> Editar</a>
                                    </div>-->








                                <div class="form-send"  v-show="info_page.index_page===5">
                                    <!--<span class="fa fa-exclamation-circle s-icon"></span>
                                  <label>Verifica las actualizaciones, ya que son finales y no puedes regresar a un punto anterior.</label>
                                  --> <div class="box-button mb-4">
                                    <button type="button" class="add-item">Crear promoción</button>
                                    <button type="button" class="add-item second">Cancelar</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import {Breadcrumb, BreadcrumbItem, Radio, Switch as LSwitch} from 'src/components/index'
    import {Select, Option, DatePicker, TimeSelect} from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';


    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'

    const propertyService = new PropertyService();
    const roomService = new RoomService();
    import { mapGetters } from "vuex";

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select,
            LSwitch,
            Radio
        },
        computed: {
            ...mapGetters(["selected_property"]),
        },
        data() {
            return {
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('promotion.index.title'),
                        path: '/admin/promotions',
                        type: '',
                    },

                    {
                        name: this.$t('promotion.new.title'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    property_id: {},
                    index_page: 1,
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    states:[
                        {
                            name: 'Sin cambios',
                            value: 0
                        },
                        {
                            name: 'Abierta',
                            value: 1
                        },
                        {
                            name: 'Cerrada',
                            value: 2
                        }
                    ],
                    room_model:{
                        name:'title',
                        value: '',
                        status: 0,
                    }
                },


                calendar_info:{
                    monthsHead: [],
                    selected: [],
                    min: '',
                    max: ''
                },
                promotion_info:{
                    options:{
                        type:[
                            {
                                name: 'Porcentaje',
                                value: '%'
                            },
                            {
                                name: 'Cantidad',
                                value: '$'
                            }
                        ],
                        discount:[
                            {
                                name: 'Noche gratis',
                                value: '0'
                            },
                            {
                                name: 'Noche con descuento',
                                value: '1'
                            }
                        ],
                        discount_apply:[
                            {
                                name: 'Primera noche',
                                value: '0'
                            },
                            {
                                name: 'Ultima noche',
                                value: '1'
                            }
                        ],
                        reserve:[
                            {
                                name: 'Todo el tiempo',
                                value: '0'
                            },
                            {
                                name: 'Días y horas específicas',
                                value: '1'
                            }
                        ]
                    },
                    visible:{
                        min_nights: false,
                        advance_options: false,
                        type: false,
                        booking: false,
                        travel: false,
                        info: false,

                    }
                },
                promotion: {
                    _id: '',
                    date_range: ['', ''],
                    end_date: '',
                    week:[1,1,1,1,1,1,1],
                    min_nights:{
                        min_value: '',
                        max_value: '',
                        max_status: false,
                    },
                    advance_options:{
                        option: '0',
                        min_days: '',
                        max_days: '',
                        max_status: false,
                        today_hours: ''
                    },
                    type:{
                        option: '0',
                        type: '%',
                        value: '',
                        limit_status: false,
                        limit: '',
                        days: ['','','','','','',''],
                        offer_type: '0',
                        discount_day: '0',
                        discount_value: '',
                        is_limit: false,
                    },
                    booking:{
                        option: '0',
                        from_date: '',
                        from_hour: 0,
                        date_range: ['', ''],
                        to_date: '',
                        to_hour: 0,
                        week:[1,1,1,1,1,1,1],
                    },
                    travel:{
                        from: '',
                        to: '',
                        date_range: ['', ''],
                        dates: [],
                    },
                    info:{
                        name: ''
                    }


                },


                pickerOptions1: {
                    disabledDate(time) {
                        let minDate = moment.now();
                       //console.log('minDate', minDate)
                        return time.getTime() <= minDate;
                    },

                },

            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='ResumeProperty') {
                        this.$refs.topProgress.start();
                        this.chargerItems(property_id);
                    }

                })
            },


            nextSection(value_){

               //console.log('avanzando seccion')
                switch (value_) {

                    case 'booking':

                        this.info_page.index_page = 2;

                }
            },




            chargerItems(property_id = null){
                this.info_page.property_id = property_id;
                let date_ = moment().locale('es');





                /* roomService.getRooms('true', property_id).then(response => {
                     response.data.data.rooms.forEach(async tnmt => {
                         this.inventory.rooms.push({
                             _id:tnmt._id,
                             name: tnmt.name,
                             value: '',
                             status: 0,
                         });
                     });

                     this.$refs.topProgress.done()
                 });*/


                /*let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month ];*/
                this.calendarDashboard(date_);
            },
            addRangeCalendar(){
                let array_dates =  this.promotion.travel.date_range;

                let date_selecteds = this.calendar_info.selected;

                let range_start = moment(array_dates[0]);
                let range_end = moment(array_dates[1]);

                let weeekdays = this.promotion.week;

                /*weeekdays.forEach((item, idx)=>{
                    if(item){

                    }
                })*/

                //console.log('range', range_start, range_end)

                while (range_start<=range_end){

                    /*let status_day = weeekdays[range_start.weekday()];

                    if(status_day){
                        let idx = date_selecteds.indexOf(range_start.format('YYYY-MM-DD'));

                        if(idx <0){

                            this.calendar_info.selected.push(range_start.format('YYYY-MM-DD'))
                        }


                    }*/

                    this.calendar_info.selected.push(range_start.format('YYYY-MM-DD'))

                    range_start.add(1, 'days');


                }
                //console.log('date', this.calendar_info.selected)

            },
            printCalendar(){
               //console.log('Se agrego al calendario');
                this.addRangeCalendar();
            },
            addMonth(month_s){
                let start_of= moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;
                let array_result = [];

                while(start_of<daysMonth){

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if(idx <0){
                        array_result.push(start_of.format('YYYY-MM-DD'))
                    }
                    start_of.add(1, 'days')
                }
                //console.log('arr_r', array_result.length)

                if(array_result.length===0){
                    this.deleteMonth(month_s)
                }else{
                    this.calendar_info.selected = date_selecteds.concat(array_result)
                }

            },
            deleteMonth(month_s){
                let start_of= moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;

                while(start_of<daysMonth){

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if(idx>=0){
                        date_selecteds.splice(idx, 1)
                    }
                    start_of.add(1, 'days')
                }
            },
            resetCalendar(){
                this.calendar_info.selected = [];
            },
            calendarDashboard(date_){
                let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month ];

            },
            dayCalendar(date){
                let aux = '';
                if(date!==null){
                    aux = moment(date, 'YYYY-MM-DD').format('D')
                }

                return aux
            },
            addCalendar(date){
                let dates_Array = this.calendar_info.selected;
                if(date!==null){

                    let idx = dates_Array.indexOf(date);
                    //console.log('idx', idx)

                    if(idx>=0){
                        dates_Array.splice(idx, 1)

                    }else{
                        dates_Array.push(date);
                    }
                }

                //console.log('dates_Array', dates_Array)
            },

            nameMonth(date){
                return moment(date, 'MM-YYYY').format('MMMM')
            },

            updDate(action){
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                if(action==='plus'){
                    date.add(1, 'M')
                    this.calendarDashboard(date)

                }else if(action==='minus'){
                    date.subtract(1, 'M')
                    this.calendarDashboard(date)
                }

            },


            checkDay(date){
                let dates_Array = this.calendar_info.selected;
                let response = '';
                let resp_status = false;

                if(date!==null){
                    response = dates_Array.indexOf(date);
                    if(response>=0){
                        resp_status = true;
                    }
                }
                return resp_status;
            },

            checkClass(idx){
                let result_class ='';
                if(idx===0){
                    result_class += 'w-80';
                }
                else if(idx===2){
                    result_class += 'ml-20 w-80';
                }
                return result_class
            },

            checkMonth(month_s){
                let start_of= moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let array_result = [];
                let array_model = [null, null, null, null,null, null, null];
                //console.log('month_s', start_of.week)

                while(start_of<daysMonth){

                    array_model[start_of.weekday()] = start_of.format('YYYY-MM-DD');
                    if(start_of.weekday() === 6){

                        array_result.push(array_model);
                        array_model = [null, null, null, null, null, null, null];
                    }

                    start_of.add(1, 'days')
                }

                array_result.push(array_model);
                //console.log('array_result', array_result)
                return array_result;

            },
            checkStatus(status){
                //console.log(status)

                let states_ = this.info_page.states[status];

                //states_.find(item => item.value === status)



                return states_.name

            },
            orderDate(){
                let calendar_array = this.calendar_info.selected;
               //console.log('orderDate', )
                calendar_array = calendar_array.sort()

                this.calendar_info.min = moment(calendar_array[0]).format('DD MMM YYYY');
                this.calendar_info.max = moment(calendar_array[calendar_array.length-1]).format('DD MMM YYYY');

                this.info_page.index_page=4;



            },
            deleteElement(section){

                switch (section) {
                    case 'min_nights':
                        this.promotion.min_nights.max_status = false;
                        this.promotion.min_nights.max_value = '';
                        break;
                    case 'advance_options':
                        this.promotion.advance_options.max_status = false;
                        this.promotion.advance_options.max_days = '';
                        break;
                    case 'type':
                        this.promotion.type.limit_status = false;
                        this.promotion.type.limit = '';
                        break;
                }

            }


        },
        mounted() {
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style lang="scss">

    .box-inventory.promotions{
        .bootstrap-switch {
            position: relative;
            top: 15px;
            float: right;
        }
        .subtitle{
            margin-bottom: 25px;
            font-weight: 600;
            font-size: 16px;
            text-transform: none;
            color: #0e0e0e;

        }
        .form-updates{
            position: relative;
        }
        span.fllw{
            display: inline-block;
            width: 100%;
        }

        .fw{
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            text-transform: none;
            color: #0e0e0e;
        }
        span.btn-link{
            margin-left: 0;
        }
        .group-edit{
            position: absolute;
            right: 10px;
            top: 10px;
            .type-text{
                margin-left: 10px;
            }

            .type-button{
                width: 45px;
                height: 45px;
                background: #38761d;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                display: inline-block;
                i{

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }
            }
        }
        .group-item{


            .item-7{
                width: 10%;
                display: inline-block;
                margin-left: 1%;
                position: relative;
                .fa{
                    position: absolute;
                    top: 37px;
                    bottom: 0;
                    margin: auto;
                    right: 5px;
                }
            }

            .input-default{
                width: 150px;
                display: inline-block;
                color: #0e0e0e;
                margin-bottom: 7px;
            }

            span.btn-link{
                color: #1D62F0;
                display: inline-block;
                font-size: 14px;

            }
            .select-default{
                display: inline-block;
                width: 150px;
                margin-bottom: 7px;
            }


        }

    }


    .box-inventory{
        border: 0;
        .form-send{
            .s-icon{
                font-size: 20px;
                color: #bf9000;
            }
            label{
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .box-button{
                button{
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
                button.second{
                    background: #ffffff;
                    color: #0e0e0e;
                }
            }

        }
        .is_title{
            font-size: 16px;
            margin: 0  0 15px;
        }
        .subtitle{
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button{
            //padding-left: 10px;
            .add-item{
                width: 100% ;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
            }
        }

        .form-dates{

            .box-dates{
                .form-check{
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually{
                width: 100% ;
                .box-calendar{
                    width: 100%;
                }
                .box-legends{
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label{
                        width: 30%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue{
                            color: #6fa8dc;
                        }
                        &.right-t{
                            text-align: right;
                            width: 40%;
                        }
                        .example-picker{
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #6fa8dc;
                            margin-right: 7px;
                            border-radius: 15px;
                            &.blocks{
                                background-color: #fff;
                                border: 1px solid;
                            }
                        }
                    }

                }
            }
        }

        .w-border{
            border: 2px solid #0e0e0e;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview{
            position: relative;
            .l-title{
                font-weight: 600;
            }
            .text-normal{
                font-size: 14px;
            }
            .brl{
                display: inline-block;
                width: 100%;
            }
            .a-right{
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }

            .type-button{
                right: 120px;
            }
            .type-text{
                height: 45px;
                line-height: 45px;
            }
            .p-contain{
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left{
                    display: inline-block;
                    width: 50%;
                }
                .s-right{
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }






        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label{
            color: #000;
            width: 100%;
            display: inline-block;
            font-size: 14px;
            text-transform: unset;
            span{
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont{
                margin-left: 0;
            }

        }
        h6{
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar{
            .item-month{

                .calendar-title{
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80{
                        width: 60%;
                    }
                    &.ml-20{
                        margin-left: 20%;
                    }
                }
                .sp-icon{
                    width: 20%;
                    text-align: center;
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child{
                    margin-right: 5%;
                }
                &:last-child{
                    margin-left: 5%;
                }
                .line-week{
                    width: 100%;
                    display: inline-block;
                    &.lw-head{
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7{
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day{
                            background: #6fa8dc;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }
                    }
                }
            }
        }


        .box-head, .box-rate, .box-restrict{
            width: 100%;
            display: inline-block;
        }
        .box-head{
            margin-top: 15px;
            label{
                font-size: 16px;
                margin-bottom: 25px;
            }
        }
        .box-item{
            display: inline-block;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
    }
</style>
